<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form  :model="formLabelAlign" class="from">
            <el-form-item label="ID：" label-width="45px" style="width: 310px;">
              <el-input
                placeholder="请输入ID"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.id"
              ></el-input>
            </el-form-item>
            <el-form-item label="名称：" label-width="60px" style="width: 310px;">
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>

            <el-form-item label="时间选择：" label-width="90px">
              <div>
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.begin_time"
                  type="date"
                  placeholder="开始日期"
                  @change="DateChange()"
                >
                </el-date-picker>
                至
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.end_time"
                  type="date"
                  placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <!-- <el-form-item label="状态：">
              <el-select
                v-model="formLabelAlign.status"
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()"
              >数据更新</i
            >
          </p>
          <div>
            <el-button
              type="primary"
              @click="on_pop_addplus()"
              icon="el-icon-plus"
              >新建</el-button
            >
            <!-- <el-button @click="task_pop" icon="el-icon-tickets">任务</el-button> -->

            <el-button @click="on_deleteList" icon="el-icon-close"
              >删除</el-button
            >
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>

            <el-table-column
              prop="begin_time"
              label="开始时间"
            ></el-table-column>
            <el-table-column prop="end_time" label="结束时间"></el-table-column>
            <el-table-column prop="nickname" label="添加人"></el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新建修改弹窗 -->
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
      <performanceredact
        @way_tableData="way_tableData"
        v-if="dialog_show"
        ref="performanceredact"
        :refbool.sync="dialog_show"
      />
    </el-dialog>
    <!-- 新建修改弹窗 -->
    <el-dialog
      title="发布任务"
      show-close
      :visible.sync="task_show"
      center
      @closed="closed_pop"
    >
      <el-form label-width="120px" style="width: 100%">
        <div style="display: flex">
          <div v-for="(item, index) in formLabelAlign.check_user" :key="index">
            <el-tag
              style="margin: 0px 5px 10px 0px"
              v-for="(tag, ins) in item.items"
              :key="ins"
              closable
              @close="on_delete(index, ins)"
            >
              {{ tag.nickname }}
            </el-tag>
          </div>
        </div>
        <el-form-item label="人员：">
          <Personnel
            type="all"
            ref="Personnel"
            v-model="formLabelAlign.check_user"
          />
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve" type="primary">发布任务</el-button>
        <el-button @click="task_show = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import performanceredact from "../../components/view_redact/performance_redact";
import Personnel from "../../components/Personnel.vue";
import {
  Calendarindex,
  inspectionsaveInfo,
  Calendardelete,
  inspectiontaskSend,
} from "../../assets/request/api";
export default {
  name: "Special_inspection",
  components: { performanceredact, Personnel },
  mixins: [table_minin],
  data() {
    return {
      title: "",
      dialog_show: false,
      formLabelAlign: {
        bengin_time: "",
        end_time: "",
        name: "",
        status: "",
        check_user: [],
      },
      //选择模板列表
      //任务
      task_show: false,
    };
  },
  created() {
    this.is_enableUrl = inspectionsaveInfo;

    this.url = Calendarindex;

    this.delete_Url = Calendardelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.begin_time
          );
        },
      };
    },
    on_skipaddplus(id) {
      this.$router.push({
        path: "/assess/Special_templatealter",
        query: {
          id: id,
        },
      });
    },
    //绘制模板
    on_template(id) {
      this.$router.push({
        path: "/assess/Special_templatealter",
        query: {
          id,
          page_type: 1,
          request_id: 1,
        },
      });
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.performanceredact.way_show(id);
      });
    },
    on_delete(index, ins) {
      this.formLabelAlign.check_user[index].items.splice(ins, 1);
    },
    on_tabclose(item, index) {
      item.items.splice(index, 1);
    },
    closed_pop() {
      this.check_user = [];
    },
    task_pop() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后在选择任务",
          type: "warning",
        });
        return;
      }
      this.task_show = true;
    },
    on_preserve() {
      let { more_List } = this;
      let check_user = this.formLabelAlign.check_user;

      if (check_user.length == 0) {
        this.$message.error("请选择人员后在发布任务");
        return;
      }
      let deparments = [];

      check_user.map((item) => {
        let check_uid = item.items.map((el) => el.id).join(",");
        deparments.push({ depart_id: item.id, check_uid });
      });
      let id = more_List.map((item) => item.id).join(",");

      let from_Data = {
        id,
        deparments,
      };
      inspectiontaskSend(from_Data).then((res) => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.task_show = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
