<template>
  <div>
  
    <div class="Personnel">
      <div class="left">
        <div class="section">
          <el-tree
            :default-expanded-keys="expandedKeys"
            empty-text="暂无部门"
            :data="Tree_data"
            :props="{
              children: 'items',
              label: 'name',
            }"
            accordion
            @node-click="handleNodeClick"
            :auto-expand-parent="true"
          >
          </el-tree>
        </div>
      </div>

      <div class="right">
        <div v-show="department_List.length != 0">
          <div class="right-div" v-for="item in department_List" :key="item.id">
            <div>
              {{ item.nickname }}
            </div>
            <el-link
              v-if="type == 'all'"
              @click="on_selectall(item)"
              type="primary"
              >选择</el-link
            >
            <el-link v-else @click="on_select(item)" type="primary"
              >选择</el-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usergetDepartUsers } from "../assets/request/api";
export default {
  name: "Personnel",
  props: ["check_user", "type"],
  model: {
    prop: "check_user",
    event: "changeData",
  },
  created() {
    usergetDepartUsers().then((res) => {
      this.Tree_data = res.data;
    });
  },

  data() {
    return {
      // 单个选择的人员
      person_name: {},
      expandedKeys: [],
      selectitem: "",
      Tree_data: [],
      department_List: [],
      //当前点击的部门
      department_Data: "",
    };
  },
  methods: {
    handleNodeClick(data) {
      this.department_Data = data;
      this.department_List = data.users;
    },
    on_select(person) {
      this.$emit("changeData", person);
    },
    on_selectall(person) {
      console.log(person);
      //判断是否是同一部门
      let { check_user, department_Data } = this;
      let isdepartment = check_user.filter((item) => {
        return item.id == department_Data.id;
      });
      if (isdepartment.length == 0) {
        check_user.push({
          id: department_Data.id,
          items: [person],
        });
      } else {
        let isselect = isdepartment[0].items.some((item) => {
          return item.id == person.id;
        });
        if (isselect) {
          this.$message({
            message: "当前人员已添加",
            type: "warning",
          });
        } else {
          isdepartment[0].items.push(person);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Personnel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  & > div {
    width: 45%;
  }
}
.left {
  border: 1px solid #f1f4f6;
  border-radius: 5px;
  height: 100%;
  overflow-y: scroll;
  padding: 5px;
}
.right {
  overflow-y: scroll;
  height: 100%;
  border: 1px solid #f1f4f6;
  border-radius: 5px;
  padding: 5px;
  .right-div {
    padding: 5px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f4f6;
  }
}
.right::-webkit-scrollbar {
  display: none; /* Chrome Safari */
  scrollbar-width: none; /* Firefox */
}
.left::-webkit-scrollbar {
  display: none; /* Chrome Safari */
  scrollbar-width: none; /* Firefox */
}
.el-divider__text {
  color: #409eff;
}
</style>