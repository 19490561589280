<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          label="标题："
          prop="name"
          :rules="[{ required: true, message: '请输入标题' }]"
        >
          <el-input
            placeholder="请输入标题"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="开始日期："
          prop="begin_time"
          :rules="[{ required: true, message: '请选择开始日期' }]"
        >
          <el-date-picker
            class="form-inp"
            value-format="yyyy-MM-dd"
            v-model="formLabelAlign.begin_time"
            type="date"
            placeholder="开始日期"
            @change="DateChange()"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="结束日期："
          prop="end_time"
          :rules="[{ required: true, message: '请选择结束日期' }]"
        >
          <el-date-picker
            class="form-inp"
            value-format="yyyy-MM-dd"
            v-model="formLabelAlign.end_time"
            type="date"
            placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendarsaveinfo, Calendarsave } from "../../assets/request/api";

export default {
  name: "student_redacy",

  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      status_list: [],
      formLabelAlign: {
        name: "",
        begin_time: "",
        end_time: "",
      },
      timeData: [],
      //统计周期结束时间的最低选择时间
      pickerOptions: {},
    };
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      Calendarsaveinfo(froms).then((res) => {
        let { info } = res.data;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { name, begin_time, end_time } = info;
          formLabelAlign.id = id;
          formLabelAlign.name = name;
          formLabelAlign.begin_time = begin_time;
          formLabelAlign.end_time = end_time;
          this.timeData = [begin_time, end_time];
        }
      });
    },
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.begin_time
          );
        },
      };
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          Calendarsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>